import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="row">
    <div className=" col main">
      <h1>{siteTitle}</h1>
      <p>
        This is the home for Mettle Code.
        <br />A Consulting company focused on improving your web presence.
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
