import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="created-by">
        © {new Date().getFullYear()}
        {` `}
        <a href="https://www.leifswenson.com">Leif Swenson</a>
      </div>
    </footer>
  )
}

export default Footer
